import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import {Snackbar, Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ToolBar from "../../components/ToolBar"
import ServiceAndUser from "./serviceAndUser";
import ExportWarehouseAndAccount from '../modal/ExportWarehouseAndAccountModal'
import CreateWarehouseAndAccount from './warehouseAndAccount/Dialog/createWarehouseAndAccount'
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import EditFormDialog from "./warehouseAndAccount/Dialog/EditDialog";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import PDFViewer from "../../components/PDFViewer";
import Button from "@mui/material/Button";

const WarehouseAndAccount = "WarehouseAndAccount";


function WarehouseAndAccountPage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({user_id: '', supplier_id: '', region: '', account_user: ''});
    const [totalRows, setTotalRows] = useState(0);
    const [PdfOpen, setPdfOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [sort_data, setSortData] = useState({})
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false, account_kwargs_1: false, account_kwargs_2: false, account_kwargs_4: false, account_kwargs_5: false,
        account_kwargs_6: false, account_kwargs_7: false
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [checked, setChecked] = React.useState({
        isDebug: {},
        isDefault: {},
        isSystemDefault: {}

    });
    const handleChange = (event, id, key) => {
        handleSave({[key]: event.target.checked}, id, "PUT", false).then(
            setChecked((prev) => ({
                ...prev,
                [key]: {

                    [id]: event.target.checked
                },
            }))
        ).catch((error) => {
            setSnackbar({open: true, message: error.response.message, severity: 'error'});
        })

    };
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {
            id: 2, field: "user", headerName: "可用用户", flex: 3, renderCell: (params) => {
                if(params.row["user"] && params.row.user.username){return params.row.user["username"]}else {
                    return ""
                }

            }
        },
        {
            id: 3, field: "supplier", headerName: "渠道商", flex: 3, renderCell: (params) => {
                if(params.row["supplier"] && params.row.supplier.name){return params.row.supplier.name}else {
                    return ""
                }
            }
        },
        {id: 4, field: "region", headerName: "口岸", flex: 8},
        {id: 5, field: "account_user", headerName: "账号名", flex: 6},
        {id: 6, field: "account_kwargs_3", headerName: "HubID", flex: 5},
        {id: 7, field: "isDebug", headerName: "是否测试账号", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked.isDebug[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "isDebug")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 8, field: "isDefault", headerName: "是否默认账号", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked.isDefault[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "isDefault")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 9, field: "isSystemDefault", headerName: "是否系统默认账号", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked.isSystemDefault[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "isSystemDefault")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 10, field: "optional", headerName: "其他参数", flex: 5},
        {id: 11, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }},
        // {id: 12, field: "account_kwargs_1", headerName: "参数1", flex: 5},
        // {id: 13, field: "account_kwargs_2", headerName: "参数2", flex: 5},
        // {id: 14, field: "account_kwargs_4", headerName: "参数4", flex: 5},
        // {id: 15, field: "account_kwargs_5", headerName: "参数5", flex: 5},
        // {id: 16, field: "account_kwargs_6", headerName: "参数6", flex: 5},
        // {id: 17, field: "account_kwargs_7", headerName: "参数7", flex: 5},
    ];
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }

    const handleSearch = (e, data) => {
        setLoading(true)
        fetchData("GET", {}, null, sort_data)
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, sort_data, open).then(r => {})

    }
    const handleSave = (editedData, rowId, method='PUT', refresh=true) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId, sort_data
        ).then((e) => {
            if(refresh){
                Refresh(false)
            }

            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, sort_data, open=true) => {
        try {
            let queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sort_data,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.warehouse_and_account;
            let requestData = {};
            let msg = "获取"
            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
                setData([]);


            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                msg = "删除"
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {

                    if (method === 'GET') {
                        let rows = res.data.rows
                        let isDebug = {}
                        let isDefault = {}
                        let isSystemDefault = {}
                        for (let i in rows){
                            let id = rows[i].id
                            isDebug[id] = rows[i].isDebug
                            isDefault[id] = rows[i].isDefault
                            isSystemDefault[id] = rows[i].isSystemDefault
                        }

                        setChecked({
                            isDebug,
                            isDefault,
                            isSystemDefault
                        })
                        setData(rows)
                        setTotalRows(res.data.total)
                    }
                    if(open){
                        setSnackbar({open: true, message: `${msg}成功！`, severity: 'success'})
                    }

                        setLoading(false);
                        // setTotalRows(res.data.total);


                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };

    useEffect(() => {
        setLoading(true)
        fetchData("GET", {}, null, sort_data, false).then()

        // 调用上面定义的函数来加载数据
    }, [currentPage, pageSize, dispatch]);
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        setSortData(sortData)
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    };
    const PdfClose =() => {
        setPdfOpen(false);
        setPdfUrl(null);
    }
    const pdf = () => {
        return (
            <Dialog
                open={PdfOpen}
                onClose={() => setPdfOpen(false)}
                maxWidth={'xl'}
                fullWidth={true}  // 使Dialog占据maxWidth所设置的宽度
                PaperProps={{
                    style: { // 这里可以设置具体的高度和宽度
                        width: '60%',
                        height: '100%'
                    }
                }}
            >
                <PDFViewer url={pdfUrl}/>
                <Button variant="outlined" onClick={PdfClose}>
                    Close
                </Button>
            </Dialog>
        );
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            {pdfUrl?pdf():""}
            <ExportWarehouseAndAccount handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></ExportWarehouseAndAccount>
            <CreateWarehouseAndAccount onSave={handleSave} setPdfOpen={setPdfOpen} setPdfUrl={setPdfUrl}></CreateWarehouseAndAccount>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default WarehouseAndAccount;
export {WarehouseAndAccountPage};