// ToolBar.js
import React from 'react';
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid-pro';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from "@mui/material/Button";

const N1ToolBar = ({ onRefresh }) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            {/*<GridToolbarFilterButton/>*/}
            <GridToolbarDensitySelector />
            <GridToolbarExport/>
            <Button startIcon={<RefreshIcon />} onClick={onRefresh}>
                刷新
            </Button>
            {/* 动态渲染额外的选项或按钮 */}
        </GridToolbarContainer>
    );
};

export default N1ToolBar;
