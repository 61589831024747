import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import {Route} from "react-router-dom";
import {Snackbar, Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ServiceAndUser from "./serviceAndUser";
import CreateServiceAndSpecial from "./ServiceAndSpecial/Dialog/ServiceAndSpecial";
import ExportServiceAndSpecial from "../modal/ExportServiceandSpecial"
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import EditFormDialog from "./ServiceAndSpecial/Dialog/EditDialog";
import Alert from "@mui/material/Alert";
import ToolBar from "../../components/ToolBar"

const ServiceAndSpecial = "ServiceAndSpecial";


function ServiceAndSpecialPage () {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({note: '',
        service_id: '',
        package_special_service: '',
        pss_status: ''});
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false,package_special_service: false, service_id: false, supplier: false
    })
    const [selectedRow, setSelectedRow] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    // const [checked, setChecked] = React.useState({});
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [sort_data, setSortData] = useState({})

    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "package_special_service__name", headerName: "特殊服务名称", flex: 8, renderCell: (params) => {
                return params.row.package_special_service.name;
            }},
        {id: 3, field: "package_special_service__note", headerName: "备注", flex: 5, renderCell: (params) => {
                return params.row.package_special_service.note;
            }},
        {id: 4, field: "service_id__name", headerName: "渠道服务", flex: 5, renderCell: (params) => {
                return params.row.service_id.name;
            }},
        {id: 5, field: "package_special_service__pss_status", headerName: "是否启用", flex: 5, renderCell: (params) => {
                return params.row.package_special_service.pss_status;
            }},
        {id:6, field: 'package_special_service', flex: 5},
        {id: 7, field: 'service_id'},
        {id: 8,field: 'supplier'},
        {id: 6, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }},
    ];
    const handleOpenEditDialog = (row) => {
        console.log(row)
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, sort_data, open).then(r => {})

    }
    const handleSearch = (e, data) => {
        setLoading(true)
        fetchData("GET", {}, null, sort_data)
    }
    const handleSave = (editedData, rowId, method='PUT') => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId, sort_data
        ).then((e) => {
            Refresh(false)
            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, sortData={}, open=true) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sortData,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.service_and_special_list;
            let requestData = {};
            let msg = "获取"
            if (method === 'GET') {

                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);


            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    // setTotalRows(1)

                    if (method === 'GET') {
                        setData(res.data.rows);
                        setLoading(false);
                        // setTotalRows(res.data.total);
                    }
                    if(open) {
                        setSnackbar({open: true, message: `${msg}成功！`, severity: 'success'});
                    }

                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true)
        fetchData('GET', {}, null, sort_data, false).then()
    }, [currentPage, pageSize, dispatch]);

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        setSortData(sortData)
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    };
    return (
        <Box sx={{
            width: '100%'
        }}>
            <ExportServiceAndSpecial handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></ExportServiceAndSpecial>
            <Grid container>

                <CreateServiceAndSpecial onSave={handleSave}></CreateServiceAndSpecial>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}
export default ServiceAndSpecial;
export {ServiceAndSpecialPage};