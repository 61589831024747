import React, {useState, useEffect} from 'react'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {Button, DialogTitle, Dialog, TextField, Box, MenuItem, DialogActions, Grid} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {AxiosSyncDefaultRequest} from "../../../components/default_request";
import server from "../../../server";

function UpdateIsRemit({selectedRow, setSnackbar, Refresh}) {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [is_remit, setIsRemit] = useState(false);
    useEffect(() => {
        console.log(selectedRow)
    }, [selectedRow]);
    const openDialog = () => {
        if (selectedRow.length > 0) {
            setOpen(true)
        }
        else {
            setSnackbar({open: true, message: "请至少选中一项", severity: "info"})
        }

    }
    const handleSubmit =  async (e) => {
        e.preventDefault();
        await AxiosSyncDefaultRequest({
            url: server.is_remit,
            method: "PUT",
            data: {ids: selectedRow},
            success: (res) => {
                // 处理成功的响应
                setOpen(false)
                if(res.status === 200){
                    setSnackbar({open: true, message: res.data.message, severity: 'success'})
                    Refresh()
                }
                else {
                    setSnackbar({open: true, message: res.data.message, severity: 'error'})
                }
            },
            fail: (error) => {
                setSnackbar({open: true, message: error, severity: 'error'})
            }
        })
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setIsRemit(value);
    }
    return (<span>

    <Button sx={{textTransform: 'none'}} startIcon={<MonetizationOnIcon/>} onClick={openDialog}>
                已退款
            </Button>
    <Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)} maxWidth='xs'>
                <DialogTitle>修改信息</DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
                是否批量修改为已打款状态
            {/*<TextField*/}
            {/*    select*/}
            {/*    required*/}
            {/*    variant="standard"*/}
            {/*    label="是否已经退款"*/}
            {/*    sx={{width: 300}}*/}
            {/*    value={is_remit}*/}
            {/*    onChange={handleChange}*/}
            {/*>*/}
            {/*    <MenuItem value={true} key={"is_remit_true"}>是</MenuItem>*/}
            {/*    <MenuItem value={false} key={"is_remit_false"}>否</MenuItem>*/}
            {/*</TextField>*/}
            </Grid>
            <DialogActions>
                            <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}}
                                    onClick={() => setOpen(false)}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained"
                                           sx={{mt: 3, mb: 2}}>
                                确认
                            </LoadingButton>
                        </DialogActions>
        </Box>
    </Dialog>
</span>)
}

export default UpdateIsRemit