import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";

import {Route} from "react-router-dom";
import {Snackbar, Switch} from "@mui/material";
import {useSelector} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import N1ToolBar from "../../components/N1ToolBar"
import ServiceAndUser from "./serviceAndUser";
import ExportWarehouse from '../modal/ExportWarehouseModal'
import CreateWarehouse from './warehouse/Dialog/createWarehouse'
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditFormDialog from "./warehouse/Dialog/EditDialog";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

const Warehouse = "Warehouse";


function WarehousePage() {
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({name: '', address1: '', address2: '', city: '', postal_code: '', state: ''});
    const user = useSelector((state) => state.auth.user);
    const [accountDisplay, setAccountDisplay] = useState([]);
    const [sort_data, setSortData] = useState({});
    const [Visibility, setVisibility] = useState({
        id: false, user: false, IATA: false,
        FEDEX_GROUND: user.permissions.includes("仓库账号展示"),
        GROUND_HOME_DELIVERY: user.permissions.includes("仓库账号展示"),
        SMART_POST: user.permissions.includes("仓库账号展示"),
    });
    const [expandedRows, setExpandedRows] = React.useState({});
    const [checked, setChecked] = React.useState({});

    const handleChange = (event, id, key) => {
        handleSave({[key]: !event.target.checked}, id, "PUT", false).then(
            setChecked((prev) => ({
                ...prev,

                [id]: !event.target.checked

            }))
        ).catch((error) => {
            setSnackbar({ open: true, message: error.response.message, severity: 'error' });
        })

    };

    useEffect(() => {
        setLoading(true)
        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, sort_data, false).then();
        if (user.permissions.includes("仓库账号展示")) {
            setAccountDisplay([
                {id: 15, field: "FEDEX_GROUND", headerName: "Ground", flex: 3, renderCell: (params) => renderCustomCell(params) },
                {id: 16, field: "GROUND_HOME_DELIVERY", headerName: "Home", flex: 3, renderCell: (params) => renderCustomCell(params) },
                {id: 17, field: "SMART_POST", headerName: "SMP", flex: 3, renderCell: (params) => renderCustomCell(params) }])
        }
    }, [currentPage, pageSize]);

    const renderCustomCell = (params) => {
        const content = params.value;
        const rowId = params.row.id;

        // 如果内容为空，显示“不可用”
        if (!content) {
            return <span>不可用</span>;
        }

        // 如果内容不为空，截取并显示后五位数
        const contentToShow = content.length > 5 ? content.slice(-5) : content;

        return (
            <div>{contentToShow}</div>
        );
    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {
            id: 2, field: "warehouse_number", headerName: "仓库编号", flex: 2
        },
        {
            id: 3, field: "name", headerName: "仓库名", flex: 5
        },
        {id: 4, field: "address1", headerName: "地址1", flex: 5},
        {id: 5, field: "address2", headerName: "地址2", flex: 2},
        {id: 6, field: "city", headerName: "城市", flex: 5},
        {id: 7, field: "postal_code", headerName: "邮编", flex: 2},
        {id: 8, field: "state", headerName: "州/省简称", flex: 3},
        {id: 9, field: "phone", headerName: "电话", flex: 3},
        {id: 12, field: "is_specific", headerName: "是否退回新火仓", flex: 4,
            renderCell: (params) => {
                return <Switch
                    checked={!checked[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_specific")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        // 仓库属性
        {id: 13, field: "address_type", headerName: "仓库属性", flex: 3, renderCell: (params) => {
            if (params.row.address_type === 1){
                return "正向物流"
            }else if (params.row.address_type === 2){
                return "双向物流"
            }
            else if (params.row.address_type === 3){
                return "逆向物流"
            }
        }},
        // 上网城市
        {id: 14, field: "online_city", headerName: "上网城市", flex: 3},
        {id: 88, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
        }},
    ];
    const index = columns.findIndex(col => col.id === 14);

    if (index !== -1) {
        columns.splice(index + 1, 0, ...accountDisplay);
    }

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }


    const handleSearch = (e) => {
        setLoading(true)
        fetchData("GET", {}, null, sort_data)
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, sort_data, open).then(r => {})

    }
    const handleSave = (editedData, rowId, method='PUT', refresh=true) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId, sort_data
        ).then((e) => {
            if(refresh){
                Refresh(false)
            }

            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, sort_data, open=true) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sort_data,
                curr_page: currentPage === 0 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.warehouse_list_url;
            let requestData = {};
            let msg = "获取"

            if (method === 'GET') {

                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                msg = "删除"
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: async (res) => {
                    // setTotalRows(1);

                    if (method === 'GET') {
                        const wac_res = await AxiosSyncDefaultRequest({
                            url: `${server.warehouse_account_service_url}`,
                            method: 'GET',
                        });

                        let rows = res.data.rows;
                        let check = {};
                        let newData = [];

                        const servicesMap = wac_res.data.rows.reduce((map, service) => {
                            const { warehouse, service_name, account_user } = service;
                            if (!map[warehouse]) {
                                map[warehouse] = [];
                            }
                            map[warehouse].push({ service_name, account_user });
                            return map;
                        }, {});

                        for (let row of rows) {
                            check[row.id] = row.is_specific;
                            let servicesData = servicesMap[row.id] || [];
                            let servicesAttributes = {};
                            for (let service of servicesData) {
                                servicesAttributes[service.service_name] = service.account_user;
                            }

                            let mergedRow = {
                                ...row,
                                ...servicesAttributes
                            };
                            newData.push(mergedRow);
                        }

                        setChecked(check);
                        setData(newData);
                        setLoading(false);
                        setTotalRows(res.data.total);
                    }

                    if (open) {
                        setSnackbar({ open: true, message: `${msg}成功！`, severity: 'success' });
                    }
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            setSnackbar()
            console.error('请求异常', error);
        }
    };

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        setSortData(sortData)
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            <ExportWarehouse handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></ExportWarehouse>
            <CreateWarehouse onSave={handleSave}></CreateWarehouse>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500, 9999]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page + 1)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <N1ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Warehouse;
export {WarehousePage};