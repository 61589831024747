import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StyledBox from "../../components/StyledBox";
import {DataGridPro} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import server from "../../server";
import {AxiosDefaultRequest, AxiosSyncDefaultRequest} from "../../components/default_request";
import {FreightShipmentBar} from "./FreightShipmentBar";
import {DialogContentText, Snackbar} from "@mui/material";
import EditFormDialog from "./Dialog/EditDialog";
import Alert from "@mui/material/Alert";
import ToolBar from "../../components/ToolBar";
import RateDetails from "../modal/rateDetails";
import PackageDisplay from "../modal/PackageDisplay";

import ClipboardHandler from "../../components/ClipboardHandler";
import APICircularProgress from "../../components/APICircularProgress";
import CreateFormDialog from "./Dialog/createDialog";
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from "@mui/lab/LoadingButton";
import UploadFreight from "./Dialog/UploadFreight";
import PickupDialog from "./Dialog/PickupDialog";
import BackendShipRecordDialog from "./Dialog/BackendShipRecordDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";


const FreightShipment = "FreightShipment";

function FreightShipmentPage() {
    const [pageSize, setPageSize] = useState(100);
    const status_dict = {
        '-1': 'Unknown',
        '1': 'Processing',
        '2': 'Finish',
        '3': 'Ordered',
        '4': 'Error',
        '5': 'UnknownError',
        '6': 'Void'
    };
    const status_chinese = {
        "-1": "未知", "1": "处理中", "2": "已出单", "3": "完成", "4": "错误", "5": "未知错误", "6": "已取消"
    }
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [downloadInfoLoading, setDownloadInfoLoading] = useState(false);
    const [voidLoading, setVoidLoading] = useState(false)
    const [aloding, setAloding] = useState(null)
    const [serviceDict, setServiceDict] = useState({});
    const [warehouse_service, SetWarehouseService] = useState([]);
    const [warehouseOptions, SetWarehouseOptions] = useState({});
    const [warehouseList, setWarehouseList] = useState([]);
    const [rateDetailsOpen, SetRateDetailsOpen] = useState({});
    const [tracking_num, setTrackingNum] = useState({});
    const [Service, setService] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [tracking_numbers, setTrackingnumbers] = useState([])
    const user = useSelector((state) => state.auth.user);
    const [columnWidths, setColumnWidths] = React.useState({

    });
    const [sort_data, setSortData] = useState({})
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        setSortData(sortData)
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, null, sortData);
    };
    const tracking_number_format = (track, ) => {
        if (track.row.status === '2' || track.row.status === '3' || track.row.status === '6') {
            return (
                <>
                    <PackageDisplay trace={track.value} trace_id={track.id}/>
                    {/*<Tooltip title={mrg}>*/}
                    {/*    <Button aria-describedby={track.id} startIcon={<CopyAllIcon/>} style={{ color: 'rgba(0, 0, 0, 0.30)' }} onClick={(e) => navigator.clipboard.writeText(track.value).then(() => {*/}
                    {/*        setMrg("√ 复制成功")*/}
                    {/*    }).catch(err => {*/}
                    {/*        setMrg("复制失败")*/}
                    {/*    })} onMouseOver={*/}
                    {/*        (e) => {setMrg("复制到粘贴板")}*/}
                    {/*    }/>*/}

                    {/*</Tooltip>*/}
                    <ClipboardHandler track={track}/>
                </>
            )
        }
        return <Button>{track.value}</Button>
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // 直接返回AxiosSyncDefaultRequest调用
                return AxiosSyncDefaultRequest({
                    url: server.warehouse_service,
                    method: "GET",
                    data: {service_name: "FEDEX_FREIGHT_ECONOMY"},
                    success: (res) => {
                        SetWarehouseService(res.data.rows)
                    },
                    fail: (error) => {
                        // console.error('请求失败', error);
                        // setData([]);
                        // setLoading(false);
                        // const errorMessage = JSON.stringify(error.response.data);
                        // setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                        // throw error;
                    }
                });

            } catch (error) {
                console.error('请求异常', error);
                throw error;
            }
        }
        fetchData()
        // 调用上面定义的函数来加载数据

    }, [currentPage, pageSize]);
    const address_type_map = {
        "-1": "未验证",
            "0": "商业",
            "1": "住宅",
            "2": "混合",
            "3": "未知",
    };
    const warehouse_format = (params) => {
        return warehouseOptions[params.value] || '';
    }
    const toggleRateDetails = (id) => {
        // 切换指定行的展开状态
        SetRateDetailsOpen(prevState => ({
            ...rateDetailsOpen,
            [id]: !rateDetailsOpen[id]
        }));
    };
    const address_map_format = (params) => {
        return address_type_map[params.value] || '';
    }
    const service_format= (number) => {
        if(serviceDict[number.value]){
            return `${serviceDict[number.value]}`
        }
        else {
            return ""
        }
    }
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [searchData, setSearchData] = useState({
        tracking_number: "", secondaryTrackNumber: "", Reference1: "", Warehouse: "", start_create_time: null, end_create_time: null,
        user: '', status: '', usps_tracking_number: ''
    });
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false,
        path: false
    })
    const columns = [
        {id: 1, field: "id", headerName: "ID"},
        {
            id: 2,
            field: "tracking_number",
            headerName: "TrackingNumber",
            width: columnWidths["tracking_number"] || 251,
            renderCell: tracking_number_format
        },
        // type: 'singleSelect', valueOptions: ['-1', '1', '2', '3', '4', '5', '6']
        {
            id: 3, field: "status", headerName: "Status", resizable: false, width: 90, renderCell: (params) => {
                let status = status_dict[params.row.status]
                let chinese_status = status_chinese[params.row.status]
                return (
                    <div className={`cellWithStatus ${status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {id: 21, field: "user", headerName: "Account", renderCell: (params) => {
            return params.row.user.username;
            }, width: columnWidths["user"] || 100},
        {id: 22, field: "UID", headerName: "UID", width: columnWidths['UID'] || 100, renderCell: (params) => {
                return params.row.user.UID;
            }},
        {id: 4, field: "create_time", headerName: "Create time", width: columnWidths['create_time'] || 100},
        {id: 5, field: "Logistic_name", headerName: "Supplier", width: columnWidths["Logistic_name"] || 80},
        {id: 6, field: "Warehouse", headerName: "Warehouse", width:columnWidths["Warehouse"] || 150, valueFormatter: warehouse_format},
        {id: 37, field: "public_fee", headerName: "FEDEX成本($)", width: columnWidths["public_fee"] || 100},
        {id: 34, field: "address_type", headerName: "address type", width: columnWidths["address_type"] || 100, valueFormatter: address_map_format},
        {id: 7, field: "Address1", headerName: "Address1", width: columnWidths["Address1"] || 200},
        {id: 33, field: "Address2", headerName: "Address2", width: columnWidths["Address2"] || 200},
        {id: 8, field: "City", headerName: "City", width: columnWidths["City"] || 200},
        {id: 9, field: "PhoneNumber", headerName: "Phone", width: columnWidths["PhoneNumber"] || 150},
        {id: 10, field: "PostalCode", headerName: "PostalCode", width: columnWidths["PostalCode"] || 100},
        {id: 11, field: "Country", headerName: "Country", width: columnWidths["Country"] || 100},
        {id: 12, field: "RecipientName", headerName: "ShipToName", width: columnWidths["RecipientName"] || 100},
        {id: 13, field: "StateOrProvinceCode", headerName: "State", width:columnWidths["StateOrProvinceCode"] || 50},
        {id: 23, field: "ServiceNumber", headerName: "Service", width:columnWidths["ServiceNumber"] || 150, valueFormatter: service_format},
        {id: 14, field: "Reference1", headerName: "Reference", width: columnWidths["Reference1"] || 150},
        {id: 15, field: "Rate", headerName: "Prepaid$", width: columnWidths["Rate"] || 100},
        {id: 16, field: "bill_fee", headerName: "Billed$", width: columnWidths["bill_fee"] || 100},
        {
            id: 27,
            field: "total_adj",
            headerName: "TotalAdj$",
            valueGetter: (params) => {
                // 确保Rate和bill_fee字段存在并且是数字类型
                const prepaid = params.row.Rate ?? 0;
                const billed = params.row.bill_fee ?? 0;
                return (billed - prepaid).toFixed(2); // 返回计算结果，并保留两位小数
            }
        },
        {id: 17, field: "MultiPackages", headerName: "Amount", width: columnWidths["MultiPackages"] || 100},
        {id: 18, field: "bill_status", headerName: "Bill Status", width: columnWidths["bill_status"] || 100},
        {id: 19, field: "all_track", headerName: "All Trace", width: columnWidths["all_track"] || 100},
        {id: 20, field: "path", headerName: "download name", width:columnWidths["path"] || 100},
        {id: 23, field: "error_msg", headerName: "error msg", width:columnWidths["error_msg"] || 100},
        {id: 36, field: "account", headerName: "account", width:columnWidths["account"] || 100},

        {
            id: 26, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    // <PreViewModal
                    //     key={`preview-${params.row.id}`}
                    //     ship_id={params.row.id}
                    //     disabled={params.row.status !== '2' && params.row.status !== '3'}
                    // />,
                    <RateDetails
                        key={`rate-details-${params.row.id}`}
                        isOpen={rateDetailsOpen[params.row.id] || false}
                        setOpen={() => toggleRateDetails(params.row.id)}
                        details={params.row.rate_details}
                        disabled={false}
                        is_freight={true}
                    />
                ]
            }
        }
        // next id 38
    ];

    const handleSave = (editedData, rowId, method='PUT') => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId, null, sort_data
        ).then((e) => {
            Refresh();
            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, body = {}, rowId = null, submitType=null, sortData={}, open=true) => {
        try {
            let new_data = JSON.parse(JSON.stringify(searchData));
            if(new_data["start_create_time"]){
                new_data["start_create_time"] = new Date(searchData["start_create_time"]).toLocaleDateString("en")
            }
            if (new_data["end_create_time"]){
                new_data["end_create_time"] = new Date(searchData["end_create_time"]).toLocaleDateString("en")
            }
            if(submitType){
                new_data["submitType"] = submitType
            }
            const queryParams = {
                ...new_data, // 将搜索条件作为查询参数
                ...sortData,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.freight_shipment_url;
            let requestData = {};

            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            } else if (method === 'PUT' && rowId != null) {
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = body;
            }else if (method === "DELETE") {
                requestUrl = server.batch_void_shipment;
                requestData = body;
            }else if (method === "POST") {
                if (body["start_create_time"]){
                    body["start_create_time"] = new Date(body["start_create_time"]).toLocaleDateString("en")
                }
                if (body["end_create_time"]){
                    body["end_create_time"] = new Date(body["end_create_time"]).toLocaleDateString("en")
                }
                requestData = body;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                headers: {"WEB-USER": user.username},
                success: (res) => {
                    if (method === 'GET') {
                        let rows = res.data.rows
                        let new_tracking_num = {}
                        // 做批量取消用
                        for(let i in rows){
                            new_tracking_num[rows[i].id] = {tracking_number: rows[i].tracking_number, status: rows[i].status, bill_status: rows[i].bill_status}
                        }
                        if(rows.length > 0){
                            setService(rows[0].ServiceNumber)
                        }
                        // 记录service_number

                        setTrackingNum(new_tracking_num)
                        setData(rows);
                        setLoading(false);

                        setTotalRows(res.data.total);
                        if(Object.keys(new_data).length > 0) {
                            setSnackbar({open: open, message: "请求成功！", severity: 'success'})
                        }

                    }
                    else if(method === "POST"){
                        // setData(data)
                        if(res.data.status){
                            setSnackbar({open: open, message: "导出成功！", severity: 'success'})
                        }
                        else{
                            setSnackbar({open: open, message: res.data.message, severity: "error"})
                        }

                    }
                    else if(method === "DELETE"){
                        setDeleteLoading(false)
                        setOpenDeleteDialog(false)
                        if(res.data.status){
                            setSnackbar({open: open, message: res.data.message, severity: 'success'})
                        }
                        else{
                            setSnackbar({open: open, message: res.data.message, severity: "error"})
                        }
                    }

                    setAloding(null)
                    return res
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    // setData([]);
                    setLoading(false);
                    setAloding(null)
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true);
        let service_d = {}
        let warehouse_options = {}
        // 调用上面定义的函数来加载数据
        fetchData('GET', {}, null, null, sort_data, false).then(r => {});
        AxiosDefaultRequest({
            url: server.warehouse_list_url,
            success: res => {
                for (const i in res.data.rows) {
                    warehouse_options[res.data.rows[i].warehouse_number] = res.data.rows[i].name;
                }
                SetWarehouseOptions(warehouse_options);
                setWarehouseList(res.data.rows);
            }
        });
        AxiosDefaultRequest({
            url: server.select_logistics_service_list + "?service_type=FreightShipment",
            success: res => {
                for (const i in res.data.rows) {
                    service_d[res.data.rows[i].service_number] = res.data.rows[i].description;
                }
                setServiceDict(service_d);
            }
        });
    }, [currentPage, pageSize]);
    const triggerSearch = (submitType = null) => {
        if(
            searchData.user || searchData.tracking_number || searchData.secondaryTrackNumber || searchData.usps_tracking_number
            || searchData.Reference1 || searchData.status || searchData.Warehouse || searchData.start_create_time
            || searchData.end_create_time
        ) {
            let method = "GET"
            let data = {}
            if (!submitType) {
                setLoading(true);
            } else {
                method = "POST"
                setAloding(APICircularProgress("success"))
                for(let key in searchData){
                    if(searchData[key]){
                        if(key === "status"){
                            data[key] = parseInt(searchData[key])
                        }else {
                            data[key] = searchData[key]
                        }
                    }
                }
            }
            // setSearch(true);
            fetchData(method, data, null, submitType, sort_data).then(r => {
            })
            setSearch(false);

        }else {
            setSnackbar({open: true, message: "请至少填写一个搜索条件。", severity: "error"})
        }
    };
    const Refresh = (open=true) => {
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, null, sort_data, open).then(r => {})
        setSearch(false);
    }

    const handleDownload = (filename) => {
        let url;
        if (server.test) {
            url = server.test_host + ":" + server.post + server.download_get_ship_url + '?file=' + filename
        }else{
            url = server.host + server.download_get_ship_url + '?file=' + filename
        }
        window.open(url, '_blank');
    };

    const downloadHandler = async () => {
        if (!selectedRow || selectedRow.length === 0){
            setSnackbar({open: true, message: '请勾选任意一条或多条进行下载!', severity: 'info'});
            return;
        }
        for (let i in selectedRow){
            if(tracking_num[selectedRow[i]].status === "6"){
                setSnackbar({open: true, message: '请勿勾选已经取消的单号！', severity: 'error'});
                return;
            }
        }
        setDownloadInfoLoading(true);
        try {
            setDownloadInfoLoading(true);
            // 假设这是从后端获取到的文件名
            const response = await fetchData('PUT', {ids: selectedRow, status: 'downloadInfo'}, selectedRow[0], null, sort_data);
            if (response && response.data.file) {
                handleDownload(response.data.file);  // 使用你现有的函数来处理下载
            } else {
                throw new Error('未获得文件名，无法下载');
            }
        } catch (error) {
            console.error('下载文件失败:', error);
        } finally {
            setDownloadInfoLoading(false);
        }
    }
    const re_tr = () => {
        let d = ""
            for(let i in tracking_numbers){
                if (parseInt(i) === tracking_numbers.length - 1){
                d += `${tracking_numbers[i]}`
                }
                else {
                    d += `${tracking_numbers[i]},`
                }
            }
        return d

    }
    const handleDelete = () => {
        let data = {Service: "20", tracking_numbers, MID: user.UID}
        setDeleteLoading(true)
        fetchData("DELETE", data, null, null, sort_data).then((e) => {
            Refresh();
        })
    }
    const openDig = async () => {
        if (!selectedRow || selectedRow.length === 0) {
            setSnackbar({open: true, message: '请勾选任意一条或至多5条进行取消!', severity: 'info'});
            return;
        }
        if(selectedRow.length > 5){
            setSnackbar({open: true, message: '最多只能选择5条进行取消!', severity: 'info'});
            return;
        }
        let new_tracking_numbers = []

        for (let i in selectedRow){
            if(tracking_num[selectedRow[i]].status === "6"){
                setSnackbar({open: true, message: '请勿勾选已经取消的单号！', severity: 'error'});
                return;
            }
            if(tracking_num[selectedRow[i]].bill_status === "是"){
                setSnackbar({open: true, message: "不能取消已经对账的单号！", severity: 'error'});
                return
            }
            else{
                new_tracking_numbers.push(tracking_num[selectedRow[i]].tracking_number)
            }
        }
        setTrackingnumbers(new_tracking_numbers)
        setOpenDeleteDialog(true)

        // setVoidLoading(true);
        // try {
        //     setVoidLoading(true);
        //     // 假设这是从后端获取到的文件名
        //     const response = await fetchData('PUT', {ids: selectedRow, status: 'downloadInfo'}, selectedRow[0]);
        //     if (response && response.data.file) {
        //         handleDownload(response.data.file);  // 使用你现有的函数来处理下载
        //     } else {
        //         throw new Error('未获得文件名，无法下载');
        //     }
        // } catch (error) {
        //     console.error('下载文件失败:', error);
        // } finally {
        //     setDownloadInfoLoading(false);
        // }
    }
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    };
    const uploadFreight = async (formData) => {
        try {
            const response = await AxiosSyncDefaultRequest({
                url: server.freight_ship,
                method: 'POST',
                data: formData, // 使用FormData作为请求体
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "WEB-USER": user.username
                },
                timeout: 150000,
                success: (res) => {
                    // 处理成功的响应
                    console.log(res)  // TODO
                },
                fail: (error) => {
                    // 处理失败的响应
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response?.data) || error.message;
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                }
            });
            return response; // 返回响应数据
        } catch (error) {
            console.error('请求异常', error);
            // 在这里处理异常
        }
    };
    const PickupSave = async (sendData, ) => {
        try {
            let requestUrl = server.pickup_url;
            let requestData = {};
            // console.log(sendData["PickupDate"])
            let date = new Date(sendData["PickupDate"]);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;  // getMonth() is zero-based
            let day = date.getDate();

            // Pad single digit numbers with a leading zero
            month = (month < 10 ? "0" : "") + month;
            day = (day < 10 ? "0" : "") + day;

            requestData["PickupDate"] = `${year}-${month}-${day}`;

            // requestData["PickupDate"] = sendData["PickupDate"].toLocaleDateString().replace("/", "-").replace("/", "-");
            console.log(requestData["PickupDate"]);
            requestData["ReadyTime"] = new Date(sendData["ReadyTime"]).toLocaleTimeString("en");
            requestData["CloseTime"] = new Date(sendData["CloseTime"]).toLocaleTimeString("en");
            requestData['Address1'] = sendData['Address1']
            requestData['Address2'] = sendData['Address2']
            requestData['CompanyName'] = sendData['Company']
            requestData['RecipientName'] = sendData['Contact']
            requestData['PostalCode'] = sendData['PostalCode']
            requestData['StateOrProvinceCode'] = sendData['State']
            requestData['City'] = sendData['City']
            requestData['PhoneNumber'] = sendData['PhoneNumber']
            requestData['warehouse'] = sendData['account']
            requestData['shipment_ids'] = sendData['shipment_ids']

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: "POST",
                data: requestData,
                headers: {"WEB-USER": user.username},
                success: (res) => {
                    if (res.data?.code === 2000 && res.data?.status) {
                        let message = `预约成功,预约码:${res.data["pickupCode"]}`
                        setSnackbar({ open: true, message: message, severity: 'success' });
                    } else if (res.data?.code >= 4000){
                        let errorMessage = res.data.message;
                        if (res.data?.errors) {
                            let errors = "";
                            let errors_key = Object.keys(res.data.errors);
                            for (let i=0;i<errors_key.length;i++) {
                                errors = errors + errors_key[i] + ":" + res.data.errors[errors_key[i]];
                            }
                            errorMessage = errorMessage + "," + errors;
                        }
                        setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    }
                    setAloding(null)
                    return res
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    // setData([]);
                    setLoading(false);
                    setAloding(null)
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    }
    const handleColumnResize = (params) => {
        setColumnWidths((prevWidths) => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };
    return (
        <Box sx={{
            width: '100%'
        }}>
            {aloding}
            <Grid container>
                <Grid container item md={12} lg={12}>
                    <Box sx={{minWidth: '100%'}}>
                        <FreightShipmentBar
                            searchData={searchData}
                            setSearchData={setSearchData}
                            search={search}
                            setSearch={triggerSearch}
                            setCurrentPage={setCurrentPage}
                        />
                        <CreateFormDialog
                            Refresh={Refresh}
                            warehouse_service={warehouse_service}
                        />
                        <UploadFreight
                            uploadFreight={uploadFreight}
                            Refresh={Refresh}
                        />
                        <LoadingButton sx={{ textTransform: 'none' }} loading={downloadInfoLoading} startIcon={<DownloadIcon />} onClick={downloadHandler}>
                            打印面单和BOL
                        </LoadingButton>

                        <PickupDialog
                            warehouse_service={warehouse_service}
                            warehouseList={warehouseList}
                            selectedRow={selectedRow}
                            rowData={data}
                            onSave={PickupSave}
                        />
                        <BackendShipRecordDialog/>
                        <LoadingButton sx={{ textTransform: 'none' }} loading={deleteLoading} startIcon={<CancelPresentationSharpIcon />} onClick={openDig}>
                            取消面单
                        </LoadingButton>
                    </Box>
                </Grid>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            onColumnResize={handleColumnResize}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            onSortModelChange={handleSortModelChange}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSelectionModelChange={(e) => {
                                setSelectedRow(e)
                            }}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>

            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>确认取消</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        确定要取消{re_tr()}吗？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>否</Button>
                    <LoadingButton
                        loading={deleteLoading}
                        variant={"contained"}
                        onClick={() => {
                            setDeleteLoading(true);
                            handleDelete()
                        }}
                    >是</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>

    )
}

export default FreightShipment;
export {FreightShipmentPage};